<template>
    <div class="page bg-white" id="activityList">
        <div class="quanbu">
            <div class="totalActivities">
                <div class="kuang">
                    <div class="shumu">{{ activityAll }}</div>
                    <div class="ttotalActivities">活动总数</div>
                </div>
            </div>
            <div class="totalActivities jingxi">
                <div class="kuang">
                    <div class="shumu">{{ haveInHand }}</div>
                    <div class="ttotalActivities">进行中</div>
                </div>
            </div>
            <div class="totalActivities weikaishi">
                <div class="kuang">
                    <div class="shumu">{{ notStarted }}</div>
                    <div class="ttotalActivities">未开始</div>
                </div>
            </div>
            <div class="totalActivities jieshu">
                <div class="kuang">
                    <div class="shumu">{{ hasEnded }}</div>
                    <div class="ttotalActivities">已结束</div>
                </div>
            </div>
        </div>
        <div class="bg-white top">
            <el-row :gutter="12" class="m_b1">
                <el-col :span="22">
                    <el-select placeholder="活动类型选择" clearable size="small" v-model="sizeForm.convention" class="m_r1" style="width: 15%">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('activityType')">
                        </el-option>
                    </el-select>
                    <el-select placeholder="活动类别选择" clearable size="small" v-model="sizeForm.actiServiceObj" class="m_r1" style="width: 15%">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('actiServiceObj')">
                        </el-option>
                    </el-select>
                    <el-select v-if="tabIndex != 1" placeholder="状态选择" clearable size="small" v-model="sizeForm.actiState" class="m_r1"style="width: 15%">
                        <el-option
                                v-for="item in state"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input placeholder="请输入活动名称" size="small" v-model="sizeForm.name" clearable style="width: 15%" class="m_r1"></el-input>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="searchListshijian">查询</el-button>
                    <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                </el-col>
                <el-col :span="2" class="text_right">
                    <el-button v-if="hasPermission('activity:activityList:Add')" icon="el-icon-plus" @click="addactiv"
                               size="small" type="primary">新增
                    </el-button>
                </el-col>
            </el-row>

            <el-menu :default-active="tabIndex" @select="handleSelectTab"mode="horizontal">
                <el-menu-item index="0">综合</el-menu-item>
                <el-menu-item index="1">评审中</el-menu-item>
            </el-menu>

            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 400px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="name" label="活动名称" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" v-if="hasPermission('feature:list:edit')"
                                 @click="edit(scope.row, 2)">{{scope.row.name}}
                        </el-link>
                        <el-link type="primary" :underline="false" v-else-if="hasPermission('feature:list:view')"
                                 @click="edit(scope.row, 1)">{{scope.row.name}}
                        </el-link>
                        <span v-else>{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="actiState" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("acti_state",scope.row.actiState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="convention" label="活动类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("activityType", scope.row.convention ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="actiServiceObj" label="活动类别" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("actiServiceObj", scope.row.actiServiceObj ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="delFlag" label="显示" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.delFlag"
                                   @change='xianshi(scope.row ,1)'
                                   :active-value="'0'"
                                   :inactive-value="'1'"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="top" width="150" label="置顶">
                    <template slot-scope="scope">
                        <el-input-number style="width: 100%" v-model="scope.row.top"
                                         @change="handleChangeTop(scope.row)" :min="1" :max="999" size="small"
                                         label="描述文字"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="地点" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="startTime" label="活动时间" width="300">
                    <template slot-scope="scope">{{ `${scope.row.startTime} - ${scope.row.endTime}` }}</template>
                </el-table-column>
                <el-table-column fixed="right" width="300" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                v-if="hasPermission('activity:activityList:see') && tabIndex =='0' && scope.row.actiState != '0' ||  hasPermission('activity:activityList:see') && tabIndex =='1'"
                                icon="el-icon-view" type="text"
                                size="mini" @click="edit(scope.row,0)">查看
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:activityList:edit')"
                                icon="el-icon-edit" type="text"
                                size="mini" @click="edit(scope.row,1)">编辑
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:activityList:delete') && tabIndex == '0' && scope.row.actiState == '0' || hasPermission('activity:activityList:delete') && tabIndex == '0' && scope.row.actiState == '6'"
                                icon="el-icon-delete" type="text"
                                size="mini" @click="adhjdelete(scope.row)">删除
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:activityList:release') && scope.row.actiState == '6'"
                                icon="el-icon-s-promotion" type="text"
                                size="mini" @click="dataProcessing(3,scope.row)">发布
                        </el-button>
                        <el-button v-if="hasPermission('activity:activityList:reject') && tabIndex == '1'"
                                   icon="el-icon-bottom" type="text"
                                   size="mini" @click="dataProcessing(2,scope.row)">驳回
                        </el-button>
                        <el-button v-if="hasPermission('activity:activityList:adopt') && tabIndex == '1'"
                                   icon="el-icon-top"
                                   type="text" size="mini" @click="dataProcessing(1,scope.row)">通过
                        </el-button>
                        <el-button v-if="hasPermission('activity:activityList:chart')"
                                   icon="el-icon-s-promotion"
                                   type="text" size="mini" @click="issue(scope.row)">流程图
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:activityList:submit') && tabIndex == '0' && scope.row.actiState == '0'"
                                icon="el-icon-caret-top" type="text"
                                size="mini" @click="dataProcessing(4,scope.row)">提交
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    :current-page="pageNo"
                    :page-size="pageSize"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="total"
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <!--流程图弹窗-->
        <el-dialog
                title="流程图"
                :visible.sync="dialogVisible1"
                width="70%"
                :close-on-click-modal="tanchuan"
                :before-close="handleClose">
            <!--流程图-->
            <el-steps :active="liucactiState" align-center>
                <el-step title="提交审核" :description="submitDate"></el-step>
                <el-step title="审核中" :description="auditTime"></el-step>
                <el-step title="审核通过" :description="passingTime"></el-step>
                <el-step title="发布" :description="releaseTime"></el-step>
                <el-step title="已结束" :description="endTime"></el-step>
            </el-steps>
            <div style="text-align: center;margin-top: 20px">
                <el-button type="primary" size="small" @click="handleClose">关闭</el-button>
            </div>
        </el-dialog>

        <seriesLabelPop @closed="closed" :series="series" :seriesActivities="seriesActivities"
                        :choice="choice"></seriesLabelPop>
        <messagePush @xiaoxiaddActivityLabel="xiaoxiaddActivityLabel" :activity="false" :messagePush='messagePush'
                     :xiaoxi="xiaoxi"></messagePush>
    </div>
</template>

<script>
    import messagePush from './messagePush'; //推送消息弹窗
    import seriesLabelPop from './seriesLabelPop'; //系列活动，活动标签弹窗
    export default {
        name: "activityList",
        components: {messagePush, seriesLabelPop},
        data() {
            return {
                sizeForm: {
                    convention: '', //活动类型
                    actiServiceObj: '', //活动类别
                    actiType: '', //类型选择
                    actiState: '',//状态选择
                    name: '', //搜索框内容
                },
                tabIndex: '0', //切换综合和评审中
                dataList: [],
                loading: false, //表的加载
                dialogVisible1: false, //流程图弹窗
                select: [{
                    value: 0,
                    label: '活动'
                }, {
                    value: 1,
                    label: '讲座'
                },],
                state: [
                    {
                        value: 0,
                        label: '草稿'
                    },
                    {
                        value: 1,
                        label: '未开始'
                    },
                    {
                        value: 2,
                        label: '进行中'
                    },
                    {
                        value: 3,
                        label: '已结束'
                    },
                    {
                        value: 6,
                        label: '待发布'
                    },
                ],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                addacivityLabel: false,
                messagePush: false,//消息推动弹窗
                xiaoxi: {},//传过去的对象
                seriesActivities: false,//系列活动、标签管理的弹窗
                series: 0,// 1为活动系列、2为活动标签弹窗
                choice: 0,
                id: '',//活动id
                userId: '',//用户id
                tanchuan: false,
                liucactiState: '',
                submitDate: '',//提交时间
                auditTime: '',//审核时间
                passingTime: '',//通过时间
                releaseTime: '',//发布时间
                endTime: '',//结束时间
                activityAll: '',
                haveInHand: '',
                notStarted: '',
                hasEnded: '',
                config: {
                    value: '',
                }
            }
        },
        mounted() {
            this.tabIndex = sessionStorage.getItem("activityListTabIndex") || '0'
            this.searchList();
        },
        methods: {
            //活动状态统计
            backstageGetInfoCount() {
                this.$axios(this.api.activitymanagement.backstageGetInfoCount, {}, 'get').then(res => {
                    if (res.status) {
                        this.activityAll = res.data.count;
                        this.haveInHand = res.data.yes_start;
                        this.notStarted = res.data.no_start;
                        this.hasEnded = res.data.yes_end;
                    }
                })
            },

            //打开弹窗标题 1为活动系列、2为活动标签弹窗
            popup(num) {
                this.series = num;
                this.seriesActivities = true;
            },

            // 重置
            resetSearch() {
                this.sizeForm = {
                    actiServiceObj: '',
                    actiType: '',
                    actiState: '',
                    name: '',
                }
                this.searchList()
            },

            //关闭弹窗
            closed() {
                this.seriesActivities = false;
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.searchList()
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.searchList()
            },

            //流程图遮罩层关闭
            handleClose(done) {
                this.dialogVisible1 = false;
            },

            searchListshijian() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.searchList();
            },

            //搜索按钮点击事件
            searchList() {
                this.backstageGetInfoCount();
                this.loading1 = true;
                let obj = {
                    current: this.pageNo,
                    size: this.pageSize,
                    queryType: this.tabIndex,
                    delFlag: 0,
                    top: 0,
                    ...this.sizeForm,
                }
                this.$axios(this.api.activitymanagement.activityList, obj, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records;
                        this.total = parseInt(res.data.total);
                        this.loading1 = false;
                        sessionStorage.removeItem("activityListTabIndex")
                    }
                })
            },

            //是否显示
            xianshi(row, num) {
                let obj = {
                    id: row.id,
                    delFlag: row.delFlag + '',
                }
                this.loading = true;
                this.$axios(this.api.activitymanagement.modifyState, obj, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg)
                        this.loading = false;
                        this.searchList();
                    }
                })
            },

            //置顶程度
            handleChangeTop(row) {
                this.$axios(this.api.activitymanagement.updateByActivityInfo, {
                    id: row.id,
                    top: row.top,
                }, 'get').then(res => {
                    if (res.status) {
                        this.$message.success('修改成功')
                    }
                    this.searchList();
                })
            },

            //消息弹窗
            xiaoxiaddActivityLabel(num, object) { //1是取消，0是确定
                console.log(this.userId)
                this.messagePush = false;
                let content = '';
                if (object.tabIndex == 1) {
                    content = object.actiContent;
                } else {
                    content = object.textarea;
                }
                let obj = {
                    actId: this.id, //活动id
                    content: content,//活动类容
                    title: object.inputbiaoti,//标题
                    serverType: object.tabIndex,//服务类型
                    messageType: object.leixing,//消息类型
                }
                if (num == 0) {
                    this.$axios(this.api.activitymanagement.activitymessagePush, obj, 'post').then((res) => {
                        console.log(res)
                        if (res.status) {
                            this.$message.success('消息推送成功')
                        }
                    })
                }
            },

            // tab切换
            handleSelectTab(index) {
                this.tabIndex = index;
                this.sizeForm.actiState = '';
                this.pageNo = 1
                this.searchList();
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            //0查看,1编辑
            edit(row, num) {
                if(num == 0) {
                    this.$router.push({path: '/activity/releaseActivities/view?id=' + row.id + "&num=" + num + '&actiState=' + row.actiState + '&tabIndex=' + this.tabIndex})
                } else {
                    this.$router.push({path: '/activity/releaseActivities/edit?id=' + row.id + "&num=" + num + '&actiState=' + row.actiState + '&tabIndex=' + this.tabIndex})
                }
            },

            //新增
            addactiv() {
                this.$router.push({path: '/activity/releaseActivities'})
            },

            //删除
            adhjdelete(row) {
                this.$confirm(`确定删除所选项吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.activitymanagement.delete + row.id, {}, 'delete').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg);
                            this.searchList();
                        }
                    })
                });
            },

            //通过，驳回请求接口
            afaffagffrfweg(num, id) {
                this.$axios(this.api.activitymanagement.modifyState, {
                    id: id,
                    actiState: num,
                }, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg);
                        this.searchList();
                    }
                })
            },

            //，1通过，2驳回，3发布，4提交，
            dataProcessing(num, row) {
                if (num == 2) {
                    this.afaffagffrfweg(0, row.id);
                } else if (num == 1) {
                    this.afaffagffrfweg(6, row.id);
                } else if (num == 3) {
                    this.afaffagffrfweg(99, row.id);
                } else if (num == 4) {
                    this.afaffagffrfweg(5, row.id);
                }
            },

            //消息推送
            push(row) {
                console.log(row)
                this.id = row.id;
                this.userId = JSON.parse(sessionStorage.getItem("userInfo")).id;
                this.messagePush = true;
                this.xiaoxi = row;
            },

            //流程图
            issue(val) {
                if (val.actiState == 5) {
                    this.liucactiState = 2;
                } else if (val.actiState == 6) {
                    this.liucactiState = 3;
                } else if (val.actiState == 3) {
                    this.liucactiState = 5;
                } else if (val.actiState == 2 || val.actiState == 1) {
                    this.liucactiState = 4;
                } else if (val.actiState == 0) {
                    this.liucactiState = 1;
                }
                this.dialogVisible1 = true;
                this.$axios(this.api.activitymanagement.findActivitySysLogListById, {
                    activityId: val.id,
                }, 'get').then(res => {
                    if (res.status) {
                        res.data.map(item => {
                            if (item.state == 99) {
                                this.releaseTime = item.createTime;
                            } else if (item.state == 3) {
                                this.endTime = item.createTime;
                            } else if (item.state == 5) {
                                this.auditTime = item.createTime;
                                this.submitDate = item.createTime;
                            } else if (item.state == 6) {
                                this.passingTime = item.createTime;
                            }
                        })
                    }
                })
                console.log(val)
            },

            //紧急下架
            offShelf(row) {
                console.log(row);
            },

            //下载签到二维码
            signCode(row) {
                this.$axios(this.api.activitymanagement.createSignCode, {id: row.id}, 'get').then(res => {
                    if (res.status) {
                        let shuurl = 'data:image/png;base64,' + res.data;
                        this.download(shuurl, row.name);
                    }
                })
            },

            //下载base64
            download(url, name) {
                // 这⾥是获取到的图⽚base64编码,这⾥只是个例⼦哈，要⾃⾏编码图⽚替换这⾥才能测试看到效果
                const imgUrl = url;
                // 如果浏览器⽀持msSaveOrOpenBlob⽅法（也就是使⽤IE浏览器的时候），那么调⽤该⽅法去下载图⽚
                if (window.navigator.msSaveOrOpenBlob) {
                    let bstr = atob(imgUrl.split(",")[1]);
                    let n = bstr.length;
                    let u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    let blob = new Blob([u8arr]);
                    window.navigator.msSaveOrOpenBlob(blob, name + "." + "png");
                } else {
                    // 这⾥就按照chrome等新版浏览器来处理
                    let a = document.createElement("a");
                    a.href = imgUrl;
                    a.setAttribute("download", name + "." + "png");
                    a.click();
                }
            }
        }
    }
</script>

<style scoped>
    .quanbu {
        display: flex;
        width: 100%;
    }

    .totalActivities {
        margin-left: 10px;
        width: 180px;
        height: 100px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-image: url("../../../assets/img/totalActivities.png");
        background-size: cover;
    }

    .jingxi {
        margin-left: 70px;
        background-image: url("../../../assets/img/haveInHand.png");
    }

    .weikaishi {
        margin-left: 70px;
        background-image: url("../../../assets/img/notStarted.png");
    }

    .jieshu {
        margin-left: 70px;
        background-image: url("../../../assets/img/hasEnded.png");
    }

    .kuang {
        margin-left: 19px;
        padding-top: 15px;
    }

    .shumu {
        font-size: 30px;
        font-weight: bold;
        color: #FFFFFF;
    }

    .ttotalActivities {
        font-size: 14px;
        font-weight: 400;
        color: #F3F3F3;
    }

    .anniu {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-right: 30px;
    }
</style>
